import React from 'react';
import classnames from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
} from '../../../../../../types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { HubModuleTypes } from '../../../../../../constants';
import { HTMLtoJSX } from '../../../../../../helpers';
import { CallToAction } from '../../../CallToActionGroup';
import styles from './AlertModule.module.scss';

export interface AlertModuleType extends HubModule {
  '@type': HubModuleTypes.AlertBanner;
  alertText: string;
  isLive: boolean;
  title: string;
  link: HubModuleCTA;
  expiry?: {
    date: string;
    instant: string;
    time: string;
    timeZone: string;
  };
}

export interface AlertModuleProps {
  module: AlertModuleType;
}

export default function AlertModule({
  module: {
    '@type': type,
    isLive,
    title,
    alertText,
    link,
    elementId,
    visualStyle,
  },
  testId,
}: PropsWithTestId<AlertModuleProps>) {
  const gridClassName = classnames('col-xs-12 col-sm-12', {
    'col-lg-7': link.type,
  });

  if (!isLive) return null;

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      <div className="container">
        <div className="row middle-md">
          <div className={gridClassName}>
            {title && (
              <div className={styles.title}>
                <h2>{title}</h2>
              </div>
            )}
            {alertText && (
              <div className={styles.text}>{HTMLtoJSX(alertText)}</div>
            )}
          </div>
          {link?.type && (
            <div className="col-xs-12 col-sm-12 col-lg-offset-1 col-lg-4 end-lg">
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                className={styles.ctaButton}
              />
            </div>
          )}
        </div>
      </div>
    </HubModuleWrapper>
  );
}

AlertModule.defaultProps = {
  testId: 'hub-alert-module',
};
